import styled from 'styled-components';
import { accent } from 'constants/theme';

export const Container = styled.div`
  padding: 4rem;
  // margin: 1rem;
  // border-radius: 10px;
  position: relative;
  text-align: center;
  color: #8f1d13;
  transition: background-color 0.85s ease;

  background-color: ${({ isVisible }) => (isVisible ? "white" : accent)};
`;
