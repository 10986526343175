import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import Gallery from 'components/gallery';
import IOExample from 'components/io-example';
import Modal from 'containers/modal';
import { graphql } from 'gatsby';
import { isMobile } from 'react-device-detect';
import Head from 'components/head';



const Index = ({ data }) => (
  <Layout>
    <Head pageTitle={data.homeJson.title} />
    <Box>
      {/* <Title as="h2" size="large" style={{ fontFamily: 'Montserrat' }}>
        {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
      </Title> */}
      <Title as="h2" size="large" style={{ fontFamily: 'Montserrat' }}>
        {data.homeJson.description}
      </Title>
      {/* <div style={{ height: '2vh' }} /> */}
    </Box>
    <Box>
      <Title as="h2" size="small" style={{ fontFamily: 'Montserrat' }}>
        {data.homeJson.hours}
      </Title>
      <Title as="h2" size="small" style={{ fontFamily: 'Montserrat' }}>
        {data.homeJson.parking}
      </Title>
    </Box>
    <Box>
      {isMobile ?
        <div>
          <a style={{ textDecoration: "none", color: "black" }} href="https://www.google.com/maps/dir/?api=1&destination=Jacob's&destination_place_id=ChIJS8h8bx-shYAR3yaF4jWyekg">
            <Title as="h6" size="small" style={{ fontFamily: 'Montserrat' }}>
              {data.homeJson.address}
            </Title>
          </a>
          <div style={{ height: '1vh' }} />
          <a style={{ textDecoration: "none", color: "black", fontWeight: "bold" }} href="tel:+17079964024">
            <Title as="h6" size="small" style={{ fontFamily: 'Montserrat' }}>
              {data.homeJson.phone}
            </Title>
          </a>
          <div style={{ height: '2vh' }} /> 
        </div>
        : <div />
      }
      <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", paddingTop: 5 }}>
        <a href="https://apps.apple.com/us/app/jacobs-restaurant/id1461508900?ls=1">
          <img style={{ maxWidth: isMobile ? 100 : 145, maxHeight: isMobile ? 45 : 62 }} alt='appstore' src={require('./assets/appstore.svg')} />
        </a>
        <div style={{ width: 10 }}></div>
        <a href="https://play.google.com/store/apps/details?id=com.rbii.glock">
          <img style={{ maxWidth: isMobile ? 110 : 135, maxHeight: isMobile ? 45 : 62 }} alt='playstore' src={require('./assets/google-play-badge.png')} />
        </a>
      </div>
    </Box>
    <Gallery items={data.homeJson.gallery} />
    {/* <div style={{ height: '1vh' }} /> */}
    <IOExample />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      description
      phone
      address
      hours
      parking
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 350, maxWidth: 400, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
